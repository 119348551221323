<template>
	<div class="card summary">
		<div class="word-item" style="display: flex;font-size: 16px;align-items: center;font-weight: 700;margin-top: -10px;margin-bottom: -5px;overflow-x: hidden;">
			<div class='p-col-9 title-item'>{{note_list.label}}</div>
			<div class='p-col-2'><Badge :value="note_list.items.length-1" class="p-mr-2"></Badge></div>
			<Button @click='addNewNote(note_list.label)' style="transform: scale(0.8,0.8);margin-top: 7px;" icon="pi pi-plus" class="p-mr-2 p-mb-2 p-col-1" title="增加新笔记"></Button>
<!-- 			<div class='p-col-1'>
				<Button class='p-col-1' @click='addNewNote(note_list.label)' style="transform: scale(0.8,0.8);margin-top: 7px;" icon="pi pi-plus" class="p-mr-2 p-mb-2" title="增加新笔记"></Button>
			</div> -->
<!-- 			<div class='p-col-1'>
				<Button style="transform: scale(0.8,0.8);margin-top: 7px;" icon="pi pi-trash" class="p-mr-2 p-mb-2 p-button-danger" title="删除该主题下所有文章"></Button>
			</div> -->
		</div>
		<div style="border-top:1px solid #ededed;padding: 2px 0px 2px 0px;"></div>
		<div class='topic_his_container'>
			<div class="word-item" title='点击编辑笔记' style="display: flex;flex-direction: column;" @mouseover="showSearchResult(j)" @mouseleave="closeSearchResult(j)"  v-for="(item, j) of note_list.items.slice(1,note_list.items.length)" :key="j">
				<div class='item-container' @click="selectNoteItem(item,'')">
					<div class='p-col-10 title-item item-container'>
						<i style="margin-right: 10px;opacity: 0.5;"><b>{{j+1}}</b></i>
						<p class="word-item" v-html="item.label"></p>
						<p v-if='item.paras_num>0'><span style='color:#0081ff;font-weight:bold;'>@ {{item.paras_num}} 条记录</span></p>
					</div>
					<div class='p-col-1' @click.stop="deleteNote(item)" title='删除笔记' style="display: flex;align-items: center;justify-content: center;color: #1fa1fc;"><i class="pi pi-trash"></i></div>
					<div class='p-col-1' title='编辑笔记' style="display: flex;align-items: center;justify-content: center;color: #1fa1fc;"><i class="pi pi-pencil"></i></div>
				</div>
				<div class='card-gray p-col-12' style="display: flex;flex-direction: column;margin-bottom: 0px;" v-if='(item.paras_num>0 && show_search_results) || (item.paras_num>0 && item.show_result)'>
				<!-- <div class='card-gray p-col-12' style="display: flex;flex-direction: column;margin-bottom: 0px;" v-if='item.paras_num>0 && item.show_result'> -->
					<!-- <p style="margin-bottom: -5px;">共<span style='color:#0081ff;font-weight:bold;'>{{item.paras_num}}</span>条记录</p> -->
					<div v-for="(para,i) of item.paras_list" @click="selectNoteItem(item,para)" :key="i" class='result-item' style="display: flex;flex-direction: column;">
						<!-- <i style="margin-right: 10px;opacity: 0.5;"><b>{{i+1}}</b></i> -->
						<p style='padding: 5px;' v-html="para"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../../service/NetWorkService.js';
	export default {
		name: 'noteList',
		props: {
			note_list_copy: {
				default: null,
				type: Object
			}
		},
		data() {
			return{
				note_list:this.note_list_copy, //此后para可在methods中操作
			}
		},
		watch: {
		    // name (val) {
		    //   this.watchName = val;
		    // },
		    note_list_copy: 'notelistChange'
		  },
		networkService:null,
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			// console.log('组件加载成功');
		},
		methods: {
			notelistChange() {
			  this.note_list = this.note_list_copy;
			},
			selectNoteItem(item,para){
				this.$emit('editeNote',item,para);
			},
			addNewNote(topic_name){
				this.$emit('addNewNote',topic_name);
			},
			deleteNote(item){
				this.$emit('deleteNote',item);
			},
			showSearchResult(j){
				this.note_list.items[j+1].show_result=true;
			},
			closeSearchResult(j){
				this.note_list.items[j+1].show_result=false;
			}
		},
		computed: {
			show_search_results() {
				return this.$appState.show_search_results;
			},
		}
		}
</script>

<style scoped lang="scss">
	.word-item {
		display: flex;
		flex-direction: row;
		padding: 2px 0px 2px 0px;
		margin: 0 auto 0 0;
	}
	
	.title-item:hover {
		// background-color: #1fa1fc;
		color: #0081ff;
	}
	
	.result-item:hover {
		// background-color: #1fa1fc;
		background-color: #CCCCCC;
	}
	
	.topic_his_container{
		max-height: 220px;
		overflow-y: auto;
	}
	
	.item-container{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	
	// .topic_his_container::-webkit-scrollbar{
	// 	display: none;
	// }
</style>
